import axios from "axios";
import { useEffect, useReducer } from "react";
import { Lookup, Statuses } from "../types/LookupTypes";
import { GameDataDTO } from "../types/DailyAssignmentTypes";

const SERVICES_URL = window.MLBBest.envVariables.SERVICES_URL;

const cache = {};

export const useLookup = (lookup: string, query?: string, baseUrl?: string): Lookup => {
  const API_URL = `${SERVICES_URL}/${baseUrl || "lookup"}`;
  const initialState: Lookup = {
    status: "IDLE",
    error: "",
    teams: [],
    positions: [],
    operations: [],
    category: [],
    games: {},
    stations: [],
    venueInfo: [],
    timingOpsNotes: [],
    //[lookup]: []
  };

  const [state, dispatch] = useReducer((state: any, action: any) => {
    switch (action.type) {
      case Statuses.FETCHING:
        return { ...initialState, status: Statuses.FETCHING };
      case Statuses.FETCHED:
        if (lookup !== "games") {
          return { ...state, status: Statuses.FETCHED, [lookup]: action.payload };
        } else {
          const schedule: GameDataDTO[] = action.payload;
          if (!schedule) {
            return { ...state, status: Statuses.FETCHED };
          }
          return {
            ...state,
            status: Statuses.FETCHED,
            games: { ...state.games, [schedule?.[0]?.gameDt]: schedule },
          };
        }
      case Statuses.ERROR:
        return { ...state, status: Statuses.ERROR, error: action.payload };
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    let cancelRequest = false;
    if (!lookup) return;
    const url = `${API_URL}/${lookup}${query || ""}`;
    const fetchData = async (): Promise<void> => {
      dispatch({ type: Statuses.FETCHING });
      if (cache[url]) {
        const data = cache[url];
        dispatch({ type: Statuses.FETCHED, payload: data });
      } else {
        try {
          axios.get(url).then(response => {
            const data = response.data;
            cache[url] = data;
            if (cancelRequest) return;
            dispatch({ type: Statuses.FETCHED, payload: data });
          });
        } catch (error) {
          if (cancelRequest) return;
          dispatch({ type: Statuses.ERROR, payload: error.message });
        }
      }
    };

    fetchData();

    return (): void => {
      cancelRequest = true;
    };
  }, [lookup, query]);

  return state;
};
